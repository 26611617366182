import orderBy from 'lodash/orderBy'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'

import {
  CheckboxField,
  FvButton,
  TextField,
  ValidatedForm,
} from '@fv/client-components'
import { type SelectFieldOption } from '@fv/client-types'
import { SelectInput } from '@/components/inputs/SelectInput'
import { supportMessage } from '@/constants'
import { useUserContext } from '@/hooks/settings'

import { useShareOpportunity } from './hooks/useShareOpportunity'

const defaults = {
  email: '',
  all: false,
  shipper: 'all',
}

type ShareProps = {
  loadId: string
  onClose: () => void
}

export const Share = ({ loadId, onClose }: ShareProps) => {
  const [fields, setFields] = useState(defaults)
  const refForm = useRef<HTMLFormElement>(null)
  const share = useShareOpportunity()
  const { data } = useUserContext()

  const shippers: SelectFieldOption[] = [
    { text: 'All shippers', value: 'all' },
    ...orderBy(
      data.user.shippers.map(s => ({
        text: s.name,
        value: s.accountId,
      })),
      v => v.text.toLowerCase(),
    ),
  ]

  function onCloseLocal() {
    setFields(defaults)
    refForm.current?.reset()
    onClose()
  }

  function onFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    const name = e.target.name
    const value = e.target.value
    setFields(f => ({ ...f, [name]: value }))
  }

  async function onValidSubmit() {
    share
      .mutateAsync({
        email: fields.email,
        loadId,
        all: fields.all,
        shipper: fields.shipper,
      })
      .then(onCloseLocal)
      .catch(e => {
        toast.error(
          e?.message || `Unable to share opportunity, ${supportMessage}`,
        )
      })
  }

  return (
    <ValidatedForm
      className="share-form"
      onValidSubmit={onValidSubmit}
      ref={refForm}
    >
      <label htmlFor="email" className="label">
        Share this via email to:
      </label>
      <TextField
        autoFocus
        className="form-control mb-6"
        disabled={share.isLoading}
        id="email"
        maxLength={200}
        name="email"
        onChange={onFieldChange}
        placeholder="e.g. joe@example.com"
        required
        type="email"
        value={fields.email}
      />
      <div className="mb-6">
        <CheckboxField
          name="all"
          label="Share all my shipments"
          onChange={e => setFields(f => ({ ...f, all: e.target.checked }))}
        />
      </div>
      <div className="mb-6">
        {fields.all && (
          <SelectInput
            className="w-auto mr-2 max-w-[13rem] b650:max-w-none b650:w-full"
            name="share-shipper"
            onChange={e => setFields(f => ({ ...f, shipper: e.target.value }))}
            options={shippers}
          />
        )}
      </div>
      <div className="flex">
        <FvButton
          type="button"
          icon="times"
          theme="default"
          onClick={onCloseLocal}
        >
          <span>Cancel</span>
        </FvButton>
        <FvButton
          type="submit"
          theme="primary"
          disabled={share.isLoading}
          fwd
          icon={share.isLoading ? 'spinner' : 'paper-plane'}
        >
          <span>Send email link</span>
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
